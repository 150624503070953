// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { MenuItem, MenuItemResponse, NavItemType } from '../../../../types';
import { FormattedMessage } from 'react-intl';
import { MENU_ICONS } from '../../../../menu-items/Icons';
import useGetSidebarMenuList from '../../../../hooks/consume_api/query/useGetSidebarMenuList';
import { processRawMenuItem } from '../../../../utils/Helpers';

// ==============================|| SIDEBAR MENU LIST ||============================== //
const menu: MenuItemResponse[] = [
    {
        id: 'application',
        title: 'application',
        type: 'group',
        children: [
            {
                id: 'users',
                title: 'users',
                type: 'item',
                icon: 'IconUserCheck',
                url: '/users'
            },
            {
                id: 'notification',
                title: 'notification',
                type: 'item',
                icon: 'IconMessages',
                url: '/notification'
            },
            {
                id: 'student',
                title: 'student',
                type: 'item',
                icon: 'IconSchool',
                url: '/students'
            },
            {
                id: 'batch',
                title: 'batch',
                type: 'item',
                icon: 'IconUsers',
                url: '/batches'
            }
        ]
    }
];

const formatMenu = (menu: MenuItemResponse) => {
    const formattedMenu: MenuItem = {
        id: menu.id,
        title: <FormattedMessage id={menu.title} />,
        type: menu.type
    };

    if (menu.icon) {
        formattedMenu.icon = MENU_ICONS[menu.icon];
    }

    if (menu.url) {
        formattedMenu.url = menu.url;
    }

    if (menu.children) {
        formattedMenu.children = menu.children.map(formatMenu);
    }

    return { ...formattedMenu };
};

const MenuList = () => {
    const { rawMenuList } = useGetSidebarMenuList();
    const processedMenu = rawMenuList.map((rawMenu) => processRawMenuItem(rawMenu));
    const allMenu = processedMenu.map((menuItem) => formatMenu(menuItem));
    console.log({ allMenu });
    const menuItem: { items: NavItemType[] } = {
        items: allMenu
    };
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
