// assets
import {
    IconBasket,
    IconBuildingCommunity,
    IconCalendar,
    IconClipboardCheck,
    IconFileCertificate,
    IconLayoutKanban,
    IconMail,
    IconMessages,
    IconNfc,
    IconSchool,
    IconUserCheck,
    IconUsers
} from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconSchool,
    IconBuildingCommunity,
    IconUsers,
    IconClipboardCheck,
    IconFileCertificate
};

export const MENU_ICONS: Record<string, any> = {
    IconUserCheck: icons.IconUserCheck,
    IconBasket: icons.IconBasket,
    IconMessages: icons.IconMessages,
    IconLayoutKanban: icons.IconLayoutKanban,
    IconMail: icons.IconMail,
    IconCalendar: icons.IconCalendar,
    IconNfc: icons.IconNfc,
    IconSchool: icons.IconSchool,
    IconBuildingCommunity: icons.IconBuildingCommunity,
    IconUsers: icons.IconUsers,
    IconClipboardCheck: icons.IconClipboardCheck,
    IconFileCertificate: icons.IconFileCertificate
};
