import { MenuItem, MenuItemResponse, NavItemType } from 'types';
import { FormattedMessage } from 'react-intl';
import { MENU_ICONS } from './Icons';

// ==============================|| MENU ITEMS ||============================== //
const menu: MenuItemResponse[] = [
    {
        id: 'application',
        title: 'application',
        type: 'group',
        children: [
            {
                id: 'users',
                title: 'users',
                type: 'item',
                icon: 'IconUserCheck',
                url: '/users'
            },
            {
                id: 'notification',
                title: 'notification',
                type: 'item',
                icon: 'IconMessages',
                url: '/notification'
            },
            {
                id: 'student',
                title: 'student',
                type: 'item',
                icon: 'IconSchool',
                url: '/students'
            },
            {
                id: 'batch',
                title: 'batch',
                type: 'item',
                icon: 'IconUsers',
                url: '/batches'
            }
        ]
    }
];

const formatMenu = (menu: MenuItemResponse) => {
    const formattedMenu: MenuItem = {
        id: menu.id,
        title: <FormattedMessage id={menu.title} />,
        type: menu.type
    };

    if (menu.icon) {
        formattedMenu.icon = MENU_ICONS[menu.icon];
    }

    if (menu.url) {
        formattedMenu.url = menu.url;
    }

    if (menu.children) {
        formattedMenu.children = menu.children.map(formatMenu);
    }

    return { ...formattedMenu };
};

const allMenu = menu.map((menuItem) => formatMenu(menuItem));

const menuItems: { items: NavItemType[] } = {
    items: allMenu
};

export default menuItems;
