import { MonthlyAttendanceAnalysis } from '../types';

export const BASEURL = process.env.REACT_APP_SERVICE_URL;
export const IMAGEPATH = process.env.IMAGE_BASE_URL;

export const PAGE_SIZE = 10;
export const CURRENT_PAGE = 1;
export const DEFAULT_STALE_TIME = 1000 * 60 * 60;

export const RECEIVER_TYPE_ID = {
    PERSONALIZED: '4739c27e-f91a-4586-845a-2f25f29eac12',
    BATCH: '814dc7c6-9d43-44e8-a52b-abc99c76c650',
    GENERAL: '8d6fa44d-63f5-4649-8c85-0d62e0d98b78'
};

export const receiverTypes = [
    {
        name: 'Personalized',
        id: RECEIVER_TYPE_ID.PERSONALIZED
    },
    {
        name: 'Batch',
        id: RECEIVER_TYPE_ID.BATCH
    },
    {
        name: 'General',
        id: RECEIVER_TYPE_ID.GENERAL
    }
];

export const allRoles = [
    { name: 'Admin', id: '1ba91629-f1cf-4b50-82a6-f8427b5cb49c' },
    { name: 'Teacher', id: '0b38ed58-8873-4ff3-a369-f6c7486f4427' },
    { name: 'Admin Officer', id: 'bb960d5c-4e68-488b-8b44-682b8480431f' },
    { name: 'Student', id: '310bf6c9-4e91-4248-9935-02a1bf4032cf' },
    { name: 'Guardian', id: '3cd2c3d9-cbed-41a7-80fc-167fe823655c' }
];
export const allOrganizations = [
    {
        name: 'Coach Up',
        id: '74444e28-89f5-45c5-9fcb-7496303809a1',
        address: 'Basundhara, Dhaka'
    }
];
export const ROLES = {
    ADMIN: '1ba91629-f1cf-4b50-82a6-f8427b5cb49c',
    TEACHER: '0b38ed58-8873-4ff3-a369-f6c7486f4427',
    ADMIN_OFFICER: 'bb960d5c-4e68-488b-8b44-682b8480431f',
    STUDENT: '310bf6c9-4e91-4248-9935-02a1bf4032cf',
    GUARDIAN: '3cd2c3d9-cbed-41a7-80fc-167fe823655c'
};

export const allRelationshipTypes = [
    { name: 'Father', id: '80b83ea4-469f-432c-9889-ba5cfa577986' },
    { name: 'Mother', id: 'b708c5fc-3328-4c8c-b5a8-7eb9cb003280' },
    { name: 'Other', id: 'd82de719-aa8e-4162-a391-50d6f2cdd704' }
];

export const RELATION_TYPE = {
    FATHER: '80b83ea4-469f-432c-9889-ba5cfa577986',
    MOTHER: 'b708c5fc-3328-4c8c-b5a8-7eb9cb003280',
    OTHER: 'd82de719-aa8e-4162-a391-50d6f2cdd704'
};

export const attendanceAnalysis: MonthlyAttendanceAnalysis[] = [
    {
        month: 'May 2024',
        data: [
            {
                classDate: '21/05/2024',
                data: [
                    {
                        id: 'd0efe8da-f49c-48cd-82c6-cb5fd8c3138d',
                        studentId: {
                            id: 'fb8fd8de-b474-4527-a5f5-77ec8d32e0a2',
                            userName: 'nahian123',
                            firstName: 'Md ',
                            lastName: 'Nahian',
                            email: 'nahian@mpower.com',
                            isSuperuser: null,
                            createdBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            updatedBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            enrolledBatches: [],
                            batches: []
                        },
                        classRoutineId: 'f3dfba15-bf8d-4a80-8ed9-d78ded8adcdd',
                        isPresent: false,
                        homeworkSubmitted: true,
                        remarks: null,
                        leaveType: null
                    },
                    {
                        id: 'ca660348-1d37-4091-80a1-6ac0f96f6085',
                        studentId: {
                            id: 'a5d0db92-d33d-4399-ad6e-d4f78496211e',
                            userName: 'anik',
                            firstName: 'Anik',
                            lastName: 'Rahman',
                            email: 'anik_rahman@aceexam.com',
                            isSuperuser: null,
                            createdBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            updatedBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            enrolledBatches: [],
                            batches: []
                        },
                        classRoutineId: 'f3dfba15-bf8d-4a80-8ed9-d78ded8adcdd',
                        isPresent: true,
                        homeworkSubmitted: false,
                        remarks: null,
                        leaveType: null
                    }
                ]
            },
            {
                classDate: '28/05/2024',
                data: [
                    {
                        id: '7d448fc8-b0cd-4b70-8894-e4b01b3dad1f',
                        studentId: {
                            id: 'fb8fd8de-b474-4527-a5f5-77ec8d32e0a2',
                            userName: 'nahian123',
                            firstName: 'Md ',
                            lastName: 'Nahian',
                            email: 'nahian@mpower.com',
                            isSuperuser: null,
                            createdBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            updatedBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            enrolledBatches: [],
                            batches: []
                        },
                        classRoutineId: 'ee3beda6-270f-4a58-b4dc-1da648496e6e',
                        isPresent: true,
                        homeworkSubmitted: true,
                        remarks: null,
                        leaveType: null
                    },
                    {
                        id: 'eb13e6ad-292e-4a6a-86b0-16069e43194d',
                        studentId: {
                            id: 'a5d0db92-d33d-4399-ad6e-d4f78496211e',
                            userName: 'anik',
                            firstName: 'Anik',
                            lastName: 'Rahman',
                            email: 'anik_rahman@aceexam.com',
                            isSuperuser: null,
                            createdBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            updatedBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            enrolledBatches: [],
                            batches: []
                        },
                        classRoutineId: 'ee3beda6-270f-4a58-b4dc-1da648496e6e',
                        isPresent: true,
                        homeworkSubmitted: false,
                        remarks: null,
                        leaveType: null
                    }
                ]
            }
        ]
    },
    {
        month: 'June 2024',
        data: [
            {
                classDate: '04/06/2024',
                data: [
                    {
                        id: '5e1add84-ea2d-4921-a2f7-a2072fe2e671',
                        studentId: {
                            id: 'fb8fd8de-b474-4527-a5f5-77ec8d32e0a2',
                            userName: 'nahian123',
                            firstName: 'Md ',
                            lastName: 'Nahian',
                            email: 'nahian@mpower.com',
                            isSuperuser: null,
                            createdBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            updatedBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            enrolledBatches: [],
                            batches: []
                        },
                        classRoutineId: '458d621f-09ba-4da6-8492-2b9282687474',
                        isPresent: true,
                        homeworkSubmitted: true,
                        remarks: null,
                        leaveType: null
                    },
                    {
                        id: '98eb1051-2121-4111-a704-488056ca22c7',
                        studentId: {
                            id: 'a5d0db92-d33d-4399-ad6e-d4f78496211e',
                            userName: 'anik',
                            firstName: 'Anik',
                            lastName: 'Rahman',
                            email: 'anik_rahman@aceexam.com',
                            isSuperuser: null,
                            createdBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            updatedBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            enrolledBatches: [],
                            batches: []
                        },
                        classRoutineId: '458d621f-09ba-4da6-8492-2b9282687474',
                        isPresent: true,
                        homeworkSubmitted: false,
                        remarks: null,
                        leaveType: null
                    }
                ]
            },
            {
                classDate: '11/06/2024',
                data: [
                    {
                        id: 'c5bb3831-432a-4483-a0d2-37d333352f48',
                        studentId: {
                            id: 'fb8fd8de-b474-4527-a5f5-77ec8d32e0a2',
                            userName: 'nahian123',
                            firstName: 'Md ',
                            lastName: 'Nahian',
                            email: 'nahian@mpower.com',
                            isSuperuser: null,
                            createdBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            updatedBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            enrolledBatches: [],
                            batches: []
                        },
                        classRoutineId: '936352fd-0e07-4af6-9725-49d43685c28a',
                        isPresent: true,
                        homeworkSubmitted: true,
                        remarks: null,
                        leaveType: null
                    },
                    {
                        id: '69aff69b-44d5-40a1-83e8-0f2aad6cce4f',
                        studentId: {
                            id: 'a5d0db92-d33d-4399-ad6e-d4f78496211e',
                            userName: 'anik',
                            firstName: 'Anik',
                            lastName: 'Rahman',
                            email: 'anik_rahman@aceexam.com',
                            isSuperuser: null,
                            createdBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            updatedBy: '770b2f8f-875c-4e33-a12d-2a4072fc5939',
                            enrolledBatches: [],
                            batches: []
                        },
                        classRoutineId: '936352fd-0e07-4af6-9725-49d43685c28a',
                        isPresent: true,
                        homeworkSubmitted: false,
                        remarks: null,
                        leaveType: null
                    }
                ]
            }
        ]
    }
];
