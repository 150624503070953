import axiosServices from '../utils/axiosServices';
import { BatchListPayload, GetRoutinePayload } from '../types';
import { AttendanceStatisticsPayload } from '../hooks/consume_api/query/useGetStudentAttendanceStatisticsListByBatch';
import { GetExamResultPayload } from '../hooks/consume_api/query/useGetExamResultByExamSubjectId';

////////////////////////////////////// USER //////////////////////////////////////

export const createUser = async (data: any) => {
    return axiosServices({
        url: `/api/v1/administration/addUserWithProfile`,
        method: 'post',
        data
    });
};
export const checkUsernameExists = async (data: any) => {
    const response = await axiosServices({
        url: `/api/v1/administration/checkUsernameExists`,
        method: 'post',
        data
    });

    return response?.data?._value;
};
export const getUserDetails = async (id: string) => {
    const response = await axiosServices({
        url: `/api/v1/administration/getUserProfile/${id}`,
        method: 'get'
    });
    return response?.data?._value;
};
export const deleteUser = async (id: string) => {
    return axiosServices({
        url: `/api/v1/administration/deleteUser/${id}`,
        method: 'get'
    });
};

export const getUserList = async () => {
    const response = await axiosServices({
        url: `/api/v1/administration/getUserList`,
        method: 'get'
    });
    return response?.data?._value;
};
export const getStudentList = async () => {
    const response = await axiosServices({
        url: `/api/v1/administration/getStudentList`,
        method: 'get'
    });
    return response?.data?._value;
};
export const getTeacherList = async () => {
    const response = await axiosServices({
        url: `/api/v1/administration/getTeacherList`,
        method: 'get'
    });
    return response?.data?._value;
};
export const getStudentListBySearch = async (data: any) => {
    const response = await axiosServices({
        url: `/api/v1/administration/studentListBySearch`,
        method: 'post',
        data
    });
    return response?.data?._value;
};
export const getGuardianListBySearch = async (data: any) => {
    const response = await axiosServices({
        url: `/api/v1/administration/getGuardianList`,
        method: 'post',
        data
    });
    return response?.data?._value;
};
export const enlistGuardian = async (data: { studentId: string; relationId: string; relationshipTypeId: string }) => {
    return axiosServices({
        url: `/api/v1/administration/addGuardian`,
        method: 'post',
        data
    });
};
export const getRoleList = async () => {
    const response = await axiosServices({
        url: `/api/v1/administration/getRoleList`,
        method: 'get'
    });
    return response?.data?._value;
};
export const getMenuList = async () => {
    const response = await axiosServices({
        url: `/api/v1/administration/getMenuList`,
        method: 'get'
    });
    return response?.data?._value;
};

///////////////////////////////////////// Course /////////////////////////////////////////
export const getCourseListByCategory = async (categoryId: string) => {
    const data = { coursecategoryId: categoryId };
    const response = await axiosServices({
        url: `/api/v1/course/getCourseList`,
        method: 'post',
        data
    });
    return response?.data?._value;
};

export const getSubjectsByCourse = async (id: string) => {
    const response = await axiosServices({
        url: `/api/v1/course/getSubjectListByCourse/${id}`,
        method: 'get'
    });
    return response?.data?._value;
};

export const getTopicListBySubjectId = async (id: string) => {
    const response = await axiosServices({
        url: `/api/v1/course/getTopicListBySubjectId/${id}`,
        method: 'get'
    });
    return response?.data?._value;
};
//////////////////////////////////////// Batch ///////////////////////////////////////////

export const createBatch = async (data: any) => {
    return axiosServices({
        url: `/api/v1/batch/addBatch`,
        method: 'post',
        data
    });
};
export const deleteBatch = async (id: string) => {
    return axiosServices({
        url: `/api/v1/batch/deleteBatch/${id}`,
        method: 'get'
    });
};
export const getBatchList = async (data: BatchListPayload) => {
    const response = await axiosServices({
        url: `/api/v1/batch/getBatchList`,
        method: 'post',
        data
    });
    return response?.data?._value;
};
export const getBatchDetail = async (id: string) => {
    const response = await axiosServices({
        url: `/api/v1/batch/getBatchById/${id}`,
        method: 'get'
    });
    return response?.data?._value;
};

//========== Profile ==========//

export const getStudentListByBatch = async (id: string) => {
    const response = await axiosServices({
        url: `/api/v1/batch/getBatchStudents/${id}`,
        method: 'get'
    });
    return response?.data?._value;
};
export const getTeacherListByBatch = async (id: string) => {
    const response = await axiosServices({
        url: `/api/v1/batch/getTeacherListByBatch/${id}`,
        method: 'get'
    });
    return response?.data?._value;
};
export const enrollStudent = async (data: { batchId: string; studentId: string }) => {
    return axiosServices({
        url: `/api/v1/batch/enrollStudent`,
        method: 'post',
        data
    });
};
export const changeStudentStatus = async (data: { batchId: string; studentId: string }) => {
    return axiosServices({
        url: `/api/v1/batch/changeStudentStatus`,
        method: 'post',
        data
    });
};
export const generateRoutineByBatch = async (batchId: string) => {
    const data = { batchId: batchId };
    return axiosServices({
        url: `/api/v1/batch/generateClassRoutines`,
        method: 'post',
        data
    });
};
export const getClassRoutineByBatch = async (data: GetRoutinePayload) => {
    const response = await axiosServices({
        url: `/api/v1/batch/getRoutineByBatchId`,
        method: 'post',
        data
    });
    return response?.data?._value;
};
export const getStudentAttendanceByRoutineId = async (classRoutineId: string) => {
    const response = await axiosServices({
        url: `/api/v1/batch/studentAttendanceListByRoutineId/${classRoutineId}`,
        method: 'get'
    });
    return response?.data?._value;
};

export const getDetailedAttendanceAnalysis = async (batchId: string) => {
    const data = { batchId: batchId };
    const response = await axiosServices({
        url: `/api/v1/batch/getAttendanceList`,
        method: 'post',
        data
    });
    return response?.data?._value;
};

export const getBatchAttendanceStatistics = async (data: AttendanceStatisticsPayload) => {
    const response = await axiosServices({
        url: `/api/v1/batch/getBatchStatistics`,
        method: 'post',
        data
    });
    return response?.data?._value;
};

// export const getBatchAttendanceStatistics = async (batchId: string) => {
//     const response = await axiosServices({
//         url: `/api/v1/batch/getBatchStatistics/${batchId}`,
//         method: 'get'
//     });
//     return response?.data?._value;
// };

export const submitAttendance = async (data: any) => {
    return axiosServices({
        url: `/api/v1/batch/addDailyAttendance`,
        method: 'post',
        data
    });
};

//=========== Exam ============//

export const createExam = async (data: any) => {
    return axiosServices({
        url: `/api/v1/exam/addExam`,
        method: 'post',
        data
    });
};
export const getExamListByBatch = async (batchId: string) => {
    const response = await axiosServices({
        url: `/api/v1/exam/examListByBatch/${batchId}`,
        method: 'get'
    });
    return response?.data?._value;
};
export const getExamSubjectDetails = async (id: string) => {
    const response = await axiosServices({
        url: `api/v1/exam/examSubjectById/${id}`,
        method: 'get'
    });
    return response?.data?._value;
};
export const getExamResultByExamSubjectId = async (data: GetExamResultPayload) => {
    const response = await axiosServices({
        url: `api/v1/exam/getExamResultByExamSubjectId`,
        method: 'post',
        data
    });
    return response?.data?._value;
};
export const distributeTopicWiseScore = async (data: any) => {
    return axiosServices({
        url: `/api/v1/exam/distributeScoreAmongTopic`,
        method: 'post',
        data
    });
};
export const scoreExam = async (data: any) => {
    return axiosServices({
        url: `/api/v1/exam/updateExamResult`,
        method: 'post',
        data
    });
};

////////////////////////////////////// Notification /////////////////////////////////

export const createNotification = async (data: any) => {
    return axiosServices({
        url: `/api/v1/notification/addNotification`,
        method: 'post',
        data
    });
};
export const getNotificationList = async (data: any) => {
    const response = await axiosServices({
        url: `/api/v1/notification/getNotificationList`,
        method: 'post',
        data
    });
    return response?.data?._value;
};
