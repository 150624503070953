import { BatchListPayload, GetRoutinePayload, UserSearchParameter } from '../types';
import { AttendanceStatisticsPayload } from '../hooks/consume_api/query/useGetStudentAttendanceStatisticsListByBatch';
import { GetExamResultPayload } from '../hooks/consume_api/query/useGetExamResultByExamSubjectId';

export const batchKeys = {
    all: ['batches'] as const,
    lists: () => [...batchKeys.all, 'list'] as const,
    list: (filters: BatchListPayload) => [...batchKeys.lists(), filters] as const,
    details: () => [...batchKeys.all, 'detail'] as const,
    detail: (id: string) => [...batchKeys.details(), id] as const
};
export const userKeys = {
    all: ['users'] as const,
    lists: () => [...userKeys.all, 'list'] as const,
    list: (filters: string | UserSearchParameter, userType?: string) =>
        [
            ...userKeys.lists(),
            {
                filters,
                userType: userType
            }
        ] as const,
    details: () => [...userKeys.all, 'detail'] as const,
    detail: (id: string) => [...userKeys.details(), id] as const
};
export const userNameExistenceKeys = {
    all: ['usernameExists'] as const,
    lists: () => [...userNameExistenceKeys.all, 'list'] as const,
    list: (filters: string) => [...userNameExistenceKeys.lists(), { filters }] as const,
    details: () => [...userNameExistenceKeys.all, 'detail'] as const,
    detail: (id: string) => [...userNameExistenceKeys.details(), id] as const
};
export const examKeys = {
    all: ['exams'] as const,
    lists: () => [...examKeys.all, 'list'] as const,
    list: (filters: string) => [...examKeys.lists(), { filters }] as const,
    details: () => [...examKeys.all, 'detail'] as const,
    detail: (id: string) => [...examKeys.details(), id] as const
};
export const notificationKeys = {
    all: ['notifications'] as const,
    lists: () => [...notificationKeys.all, 'list'] as const,
    list: (filters: string | { organizationId: string }) => [...notificationKeys.lists(), { filters }] as const,
    details: () => [...notificationKeys.all, 'detail'] as const,
    detail: (id: string) => [...notificationKeys.details(), id] as const
};
export const examSubjectKeys = {
    all: ['examSubjects'] as const,
    lists: () => [...examSubjectKeys.all, 'list'] as const,
    list: (filters: string) => [...examSubjectKeys.lists(), { filters }] as const,
    details: () => [...examSubjectKeys.all, 'detail'] as const,
    detail: (id: string) => [...examSubjectKeys.details(), id] as const
};
export const classRoutineKeys = {
    all: ['classRoutines'] as const,
    lists: () => [...classRoutineKeys.all, 'list'] as const,
    list: (filters: string | GetRoutinePayload) => [...classRoutineKeys.lists(), { filters }] as const,
    details: () => [...classRoutineKeys.all, 'detail'] as const,
    detail: (id: string) => [...classRoutineKeys.details(), id] as const
};
export const attendanceKeys = {
    all: ['attendance'] as const,
    lists: () => [...attendanceKeys.all, 'list'] as const,
    list: (filters: string) => [...attendanceKeys.lists(), { filters }] as const,
    details: () => [...attendanceKeys.all, 'detail'] as const,
    detail: (id: string) => [...attendanceKeys.details(), id] as const
};
export const attendanceDetailsKeys = {
    all: ['attendanceDetails'] as const,
    lists: () => [...attendanceDetailsKeys.all, 'list'] as const,
    list: (filters: string) => [...attendanceDetailsKeys.lists(), { filters }] as const,
    details: () => [...attendanceDetailsKeys.all, 'detail'] as const,
    detail: (id: string) => [...attendanceDetailsKeys.details(), id] as const
};
export const attendanceStatisticsKeys = {
    all: ['attendanceStatistics'] as const,
    lists: () => [...attendanceStatisticsKeys.all, 'list'] as const,
    list: (filters: string | AttendanceStatisticsPayload) => [...attendanceStatisticsKeys.lists(), { filters }] as const,
    details: () => [...attendanceStatisticsKeys.all, 'detail'] as const,
    detail: (id: string) => [...attendanceStatisticsKeys.details(), id] as const
};
export const topicKeys = {
    all: ['topics'] as const,
    lists: () => [...topicKeys.all, 'list'] as const,
    list: (subjectId: string) => [...topicKeys.lists(), { subjectId }] as const,
    details: () => [...topicKeys.all, 'detail'] as const,
    detail: (id: string) => [...topicKeys.details(), id] as const
};
export const menuKeys = {
    all: ['menu'] as const,
    lists: () => [...menuKeys.all, 'list'] as const,
    list: (filters: string) => [...menuKeys.lists(), { filters }] as const,
    details: () => [...menuKeys.all, 'detail'] as const,
    detail: (id: string) => [...menuKeys.details(), id] as const
};
export const courseKeys = {
    all: ['courses'] as const,
    lists: () => [...courseKeys.all, 'list'] as const,
    list: (filters: string) => [...courseKeys.lists(), { filters }] as const,
    details: () => [...courseKeys.all, 'detail'] as const,
    detail: (id: string) => [...courseKeys.details(), id] as const
};
export const subjectKeys = {
    all: ['subjects'] as const,
    lists: () => [...subjectKeys.all, 'list'] as const,
    list: (filters: string) => [...subjectKeys.lists(), { filters }] as const,
    details: () => [...subjectKeys.all, 'detail'] as const,
    detail: (id: string) => [...subjectKeys.details(), id] as const
};
export const examResultKeys = {
    all: ['examResult'] as const,
    lists: () => [...examResultKeys.all, 'list'] as const,
    list: (filters: string | GetExamResultPayload) => [...examResultKeys.lists(), { filters }] as const,
    details: () => [...examResultKeys.all, 'detail'] as const,
    detail: (id: string) => [...examResultKeys.details(), id] as const
};
