import { useEffect, useState } from 'react';
import { RawMenuItemResponse } from '../../../types';
import { useQuery } from '@tanstack/react-query';
import { menuKeys } from '../../../react_query/query-keys';
import { getMenuList } from '../../../services/Service';

const UseGetSidebarMenuList = (dependencies: boolean = true) => {
    const [initiateFetchingMenuList, setInitiateFetchingMenuList] = useState(true);

    const [rawMenuList, setRawMenuList] = useState<RawMenuItemResponse[]>([]);

    const result = useQuery<RawMenuItemResponse[], Error>({
        queryKey: menuKeys.all,
        queryFn: getMenuList,
        // staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingMenuList && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setRawMenuList(result.data);
        }
    }, [result.data]);

    return {
        rawMenuList,
        ...result,
        setInitiateFetchingMenuList
    };
};

export default UseGetSidebarMenuList;
